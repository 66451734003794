import {createReducer} from "deox"

import {Wallpaper} from "../entities/Wallpaper"
import {ItemsState, RefineState} from "../entities/State"
import {List} from "../entities/Response"

import {
    createWallpaper,
    fetchWallpapers,
    removeWallpaper,
    refineWallpapers,
    updateWallpaper
} from "../actions/wallpapers"

export interface WallpapersState extends ItemsState<Wallpaper> {
}

const defaultWallpapersState: WallpapersState = {
    search: undefined,
    pageToken: undefined,
    orderBy: undefined,
    orderDirection: undefined,
    items: undefined,
    loadedAll: false,
}

export default createReducer(defaultWallpapersState, handleAction => [
    handleAction(fetchWallpapers.complete,
        (state: WallpapersState, event: { type: string, payload: List<Wallpaper> }) => {
            // const add = event.payload
            return state
        }),
    handleAction(removeWallpaper.complete,
        (state: WallpapersState, event: { type: string, payload: { id: number } }) => {
            return state
        }),
    handleAction(refineWallpapers.complete,
        (state: WallpapersState, event: { type: string, payload: RefineState }) => {
            return state
        }),

    handleAction(createWallpaper.complete,
        (state: WallpapersState, event: { type: string, payload: Wallpaper }) => {
            return state
        }),
    handleAction(updateWallpaper.complete,
        (state: WallpapersState, event: { type: string, payload: Wallpaper }) => {
            return state
        }),
])