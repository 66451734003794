import React, {ChangeEvent, useEffect} from "react"
import clsx from "clsx"

import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import {PromoCode} from "../../entities/PromoCode"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import CardHeader from "@material-ui/core/CardHeader"
import IconButton from "@material-ui/core/IconButton"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt"
import SlideshowIcon from "@material-ui/icons/Slideshow"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import {deepOrange, red, green} from "@material-ui/core/colors"

import {readImageUrl} from "../../utils/image"

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    addMedia: {
        position: "relative",
        '& img': {
            maxWidth: "100%",
            height: "160px",
            filter: "grayscale(1)"
        },
        '& button': {
            position: "absolute",
            zIndex: 2,
            left: "50%",
            top: "50%",
            marginTop: "-20px",
            marginLeft: "-20px",
            color: "red",
        }
    },
    media: {
        paddingTop: "56.25%",
    },
    defaultMedia: {
        backgroundSize: "160px 160px",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": "2",
        "-webkitBoxOrient": "vertical"
    },
    green: {
        width: "24px",
        height: "24px",
        fontSize: "11px",
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: green[500],
    },
    yellow: {
        width: "24px",
        height: "24px",
        fontSize: "11px",
        color: theme.palette.getContrastText(deepOrange[300]),
        backgroundColor: deepOrange[300],
    },
    red: {
        width: "24px",
        height: "24px",
        fontSize: "11px",
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    remove: {
        float: "right",
        right: "-12px",
    },
    content: {
        flexGrow: 1,
    },
}))

interface PromoItemProps extends PromoCode {
    onChange: (promo: PromoCode) => void,
    onDelete: () => void,
}

const defaultMedia = "promo-default.png"

interface StatisticsProps {
    icon: React.ComponentType<any>,
    min: number,
    max: number
}

function renderStatistics(props: StatisticsProps) {
    const Icon = props.icon
    return <>
        <Icon style={{color: "grey", marginTop: "10px", marginBottom: "10px"}}/>
        <span style={{color: "grey"}}>{props.min}/{props.max}</span>
    </>
}

export default function PromoListItem(props: PromoItemProps) {
    const classes = useStyles()

    const codeRef = React.useRef(null)
    const fileSelector = React.useRef<HTMLInputElement>(null)
    const isCodeDisabled = React.useMemo(() => !!props.code, [props.code])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [isEditing, setEdit] = React.useState(false)
    const [code, setCode] = React.useState(props.code)
    const [title, setTitle] = React.useState(props.title)
    const [maxRemove, setMaxRemove] = React.useState(props.maxRemoveCount)
    const [preview, setPreview] = React.useState(props.preview)
    const [previewUrl, setPreviewUrl] = React.useState(props.preview as string|undefined)

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleEdit = () => {
        handleCloseMenu()
        setEdit(true)
    }
    const handleDelete = () => {
        handleCloseMenu()
        props.onDelete()
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }
    const handleSaveEdit = () => {
        setEdit(false)
        props.onChange({
            title,
            code,
            preview,
            openCount: props.openCount,
            openUniqueCount: props.openUniqueCount,
            useCount: props.useCount,
            useUniqueCount: props.useUniqueCount,
            maxRemoveCount: maxRemove,
            removeCount: props.removeCount
        })
    }
    const handleCancelEdit = () => {
        setEdit(false)
        if (!props.code && !props.title) {
            props.onDelete()
        } else {
            setTitle(props.title)
            setMaxRemove(props.maxRemoveCount)
            setPreviewUrl(props.preview as string|undefined)
        }
    }
    const handleImagePick = () => {
        if (!previewUrl) {
            if (fileSelector.current) {
                const input = fileSelector.current
                input.value = ""
                if (!/safari/i.test(navigator.userAgent)) {
                    input.type = ""
                    input.type = "file"
                }
                fileSelector.current.click()
            }
            return
        }
        setPreviewUrl(undefined)
    }
    const handleImageSelection = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files &&
            event.currentTarget.files.length > 0) {
            const file = event.currentTarget.files[0]
            setPreview(file)
            readImageUrl(file).subscribe(s => {
                setPreviewUrl(s)
            })
        }
    }
    const isRed = props.removeCount >= maxRemove && maxRemove > 0
    const isYellow = ((props.removeCount * 100) / maxRemove) >= 40
    const isGreen = maxRemove === 0 || ((props.removeCount * 100) / maxRemove) < 40
    useEffect(() => {
        if (!props.code) {
            setEdit(true)
            setTimeout(() => {
                // @ts-ignore
                codeRef.current.getElementsByTagName("input")[0].focus()
            }, 10)
        }
    }, [])
    return <>
        <Card onDoubleClick={() => setEdit(true)} className={classes.card}>
            {
                isEditing
                    ? <>
                        <input ref={fileSelector}
                               onChange={handleImageSelection}
                               type="file" hidden={true}
                               accept="image/png, image/jpeg"/>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box mb={2}>
                                        <TextField onChange={e => setCode(e.target.value)}
                                                   disabled={isCodeDisabled}
                                                   ref={codeRef}
                                                   label="Код" value={code}/>
                                    </Box>
                                    <Box mb={2}>
                                        <TextField onChange={e => setTitle(e.target.value)}
                                                   label="Название" value={title}/>
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            label="Макс кол дизлайков"
                                            type="number"
                                            onChange={e => setMaxRemove(parseInt(e.target.value))}
                                            value={maxRemove}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box m={3} className={classes.addMedia} >
                                        <IconButton onClick={handleImagePick} aria-label="add-image">
                                            {previewUrl ? <HighlightOffIcon/> : <AddCircleOutlineIcon/>}
                                        </IconButton>
                                        <img src={previewUrl || defaultMedia}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleSaveEdit} disabled={!(code && title)} size="small"
                                    color="primary">
                                Сохранить
                            </Button>
                            <Button onClick={handleCancelEdit} size="small" color="default">
                                Отмена
                            </Button>
                        </CardActions>
                    </>
                    : <>
                        <CardHeader
                            avatar={
                                <Avatar className={clsx(isRed && classes.red,
                                    isYellow && classes.yellow, isGreen && classes.green)}>
                                    {` `}
                                </Avatar>
                            }
                            action={
                                <IconButton onClick={handleOpenMenu} aria-label="settings">
                                    <MoreVertIcon/>
                                </IconButton>}
                            title={props.title}
                            subheader={props.code}>
                        </CardHeader>
                        <CardMedia className={clsx(classes.media, !props.preview && classes.defaultMedia)}
                                   image={`${props.preview || defaultMedia}`}
                                   title={props.title}
                        />
                        <CardActions>
                            {renderStatistics({icon: ThumbDownAltIcon, min: props.removeCount, max: props.maxRemoveCount})}
                            {renderStatistics({icon: SlideshowIcon, min: props.openUniqueCount, max: props.openCount})}
                            {renderStatistics({icon: FileCopyIcon, min: props.useUniqueCount, max: props.useCount})}
                        </CardActions>
                    </>
            }
        </Card>
        <Menu
            id={`promo-menu-${props.code}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
            <MenuItem onClick={handleEdit}>Редактировать</MenuItem>
            <MenuItem onClick={handleDelete}>Удалить</MenuItem>
        </Menu>
    </>
}