import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {makeStyles} from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import CssBaseline from "@material-ui/core/CssBaseline"
import Fab from "@material-ui/core/Fab"
import Container from "@material-ui/core/Container"

import {useClient} from "../ioc"
import WallpaperClient from "../../api/WallpaperClient"

import Copyright from "../copyright"
import Searchbar, {Order} from "../searchbar"
import DeletionDialog from "../confirm"

import CreationDialog from "./CreationDialog"
import Content from "./Content"

import {AppState} from "../../reducers"
import {WallpapersState} from "../../reducers/wallpapers"

import {fetchWallpapers, refineWallpapers, removeWallpaper, updateWallpaper} from "../../actions/wallpapers"
import {Wallpaper} from "../../entities/Wallpaper"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        '& th': {
            borderBottom: `0px !important`
        }
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    floating: {
        position: "fixed",
        bottom: "45px",
        right: "24px"
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}))

export default function WallpapersList() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const client = useClient(WallpaperClient)

    const {items, search, orderBy, orderDirection, loadedAll} = useSelector<AppState, WallpapersState>(
        state => state.wallpapers)
    const fetching = useSelector((state: AppState) => state.ui.requests.login.isActive())
    const [deleteWallpaperState, setDeleteWallpaperState] = React.useState({
        show: false, id: 0
    })
    const [showCreationDialog, setShowCreationDialog] = React.useState(false)

    const onDeleteConfirm = (confirm: boolean) => {
        if (confirm) {
            dispatch(removeWallpaper(client, deleteWallpaperState))
        }
        setDeleteWallpaperState({show: false, id: 0})
    }

    useEffect(() => {
        const onScroll = () => {
            if ((window.scrollY + window.innerHeight) >= document.body.scrollHeight) {
                if (!(fetching || loadedAll)) {
                    dispatch(fetchWallpapers())
                }
            }
        }
        window.addEventListener("scroll", onScroll)
        return function cleanup() {
            window.removeEventListener("scroll", onScroll)
        }
    }, [loadedAll])
    useEffect(() => {
        if (!items) {
            dispatch(fetchWallpapers())
        }
        return function cleanup() {
        }
    }, [])
    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.root}>
                <Searchbar title="Wallpapers"
                           search={search}
                           orderBy={orderBy}
                           orderDirection={orderDirection}
                           onOrderChange={(field: string, direction: Order) => {
                               dispatch(refineWallpapers({orderBy: field, orderDirection: direction}));
                           }}
                           onSearchChange={text => {
                               dispatch(refineWallpapers({search: text}));
                           }}/>
                <Container className={classes.cardGrid} maxWidth="lg">
                    <Content items={items} fetching={fetching}
                             onUpdateRequest={(id: number, item: Wallpaper) => {
                                 dispatch(updateWallpaper(client, item));
                             }}
                             onDeleteRequest={(id) => {
                                 setDeleteWallpaperState({show: true, id})
                             }}/>
                </Container>
            </main>
            <DeletionDialog show={deleteWallpaperState.show} onClose={onDeleteConfirm}/>
            <CreationDialog show={showCreationDialog} onClose={() => setShowCreationDialog(false)}/>
            <footer className={classes.footer}>
                <Copyright/>
            </footer>
            <div className={classes.floating}>
                <Fab onClick={() => setShowCreationDialog(true)} color="primary" aria-label="add">
                    <AddIcon/>
                </Fab>
            </div>
        </React.Fragment>
    )
}