import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {makeStyles} from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import SendIcon from "@material-ui/icons/Send"
import CssBaseline from "@material-ui/core/CssBaseline"
import Fab from "@material-ui/core/Fab"
import Container from "@material-ui/core/Container"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import Copyright from "../copyright"
import ConfirmDialog from "../confirm"
import Searchbar, {HeadCell, Order} from "../../components/searchbar"
import PromoListItem from "./PromoListItem"

import {useClient} from "../ioc"
import PromoClient from "../../api/PromoClient"
import {AppState} from "../../reducers"
import {PromoState} from "../../reducers/promo"
import {addPromoCode, editPromoCode, removePromoCode, searchPromoCodes} from "../../actions/promo"
import {PromoCode} from "../../entities/PromoCode"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        '& th': {
            borderBottom: `0px !important`
        }
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    floating: {
        position: "fixed",
        bottom: "45px",
        right: "24px",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    backdrop: {
        background: "none",
        zIndex: 2,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    notFound: {
        textAlign: "center",
        '& img': {
            maxWidth: "400px"
        }
    },
}))

interface HeadListItem {
    name: string,
    disableCount: string,
    useCount: string
}

const headCells: HeadCell<HeadListItem>[] = [
    {id: "name", label: "по имени", disablePadding: true},
    {id: "useCount", label: "по популярности", disablePadding: true},
    {id: "disableCount", label: "по дизлайкам", disablePadding: true},
]

function renderNotFound(classes: any) {
    return <div className={classes.notFound}>
        <img src={"/nothing-found.svg"}/>
        <Typography variant="h5" component="h2">
        </Typography>
    </div>
}

function renderSkeleton(classes: any) {
    return <></>
}

export default function PromoList() {
    const classes = useStyles()

    const dispatch = useDispatch()
    const client = useClient(PromoClient)

    const {items, search, orderBy, orderDirection, loadedAll, pageToken} = useSelector<AppState, PromoState>(
        state => state.promo)
    const showLoader = useSelector((state: AppState) => state.ui.requests.promo.isActive())

    const [deletePromo, setDeletePromo] = React.useState({show: false, id: ""})
    const [sendPush, setSendPush] = React.useState({show: false})

    useEffect(() => {
        if (items?.length === 0) {
            handleSearch(search, orderBy, orderDirection)
        }
        const onScroll = () => {
            if ((window.scrollY + window.innerHeight) >= document.body.scrollHeight) {
                if (!(showLoader || loadedAll)) {
                    dispatch(searchPromoCodes(client, {pageToken, search, orderBy, orderDirection}))
                }
            }
        }
        window.addEventListener("scroll", onScroll)
        return function cleanup() {
            window.removeEventListener("scroll", onScroll)
        }
    }, [])
    const handleSearch = (search?: string, orderBy?: string, orderDirection?: "asc" | "desc") => {
        dispatch(searchPromoCodes(client, {
            pageToken: undefined,
            search,
            orderBy,
            orderDirection
        }))
    }
    const handleAddPromoCode = () => {
        dispatch(addPromoCode(client, {
            code: "",
            title: "Title"
        }))
    }
    const handleEditPromoCode = (promo: PromoCode) => {
        dispatch(editPromoCode(client, promo))
    }
    const handleSendPush = () => {
        setSendPush({show: true})
    }
    const handleSendPushConfirm = (confirm: boolean) => {
        if (confirm) {
            client.sendPushMessage({
                title: "New Roblox Promo Codes!",
                content: "Check out awesome new content, available for free!"
            }).subscribe()
        }
        setSendPush({show: false})
    }
    const handleDelete = (code: string) => {
        if (!code) {
            dispatch(removePromoCode(client, {code}))
        } else {
            setDeletePromo({show: true, id: code})
        }
    }
    const handleDeleteConfirm = (confirm: boolean) => {
        if (confirm) {
            dispatch(removePromoCode(client, {code: deletePromo.id}))
        }
        setDeletePromo({show: false, id: ""})
    }
    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.root}>
                <Searchbar title="Промо-коды"
                           search={search}
                           orderBy={orderBy}
                           orderDirection={orderDirection}
                           orderFields={headCells}
                           onOrderChange={(field: string, direction: Order) => {
                               handleSearch(search, field, direction)
                           }}
                           onSearchChange={text => {
                               handleSearch(text, orderBy, orderDirection)
                           }}/>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {pageToken === undefined ? renderSkeleton(classes) :
                        items?.length === 0 ? renderNotFound(classes)
                            : <Grid container spacing={4}>
                                {items?.map(it => (
                                    <Grid item key={it.code} xs={12} sm={6} md={4}>
                                        <PromoListItem onChange={handleEditPromoCode}
                                                       onDelete={() => handleDelete(it.code)}
                                                       {...it} />
                                    </Grid>
                                ))
                                }
                            </Grid>
                    }
                </Container>
            </main>
            <Backdrop className={classes.backdrop} open={showLoader}>
                <CircularProgress size={50}/>
            </Backdrop>
            <ConfirmDialog show={deletePromo.show} onClose={handleDeleteConfirm} title={`Удалить ${deletePromo.id}?`}/>
            <ConfirmDialog show={sendPush.show} onClose={handleSendPushConfirm} title="Отправить push-сообщение"
                           description=" "/>
            <footer className={classes.footer}>
                <Copyright/>
            </footer>
            <div className={classes.floating}>
                <Fab onClick={handleAddPromoCode} color="primary" aria-label="add">
                    <AddIcon/>
                </Fab>
                <Fab onClick={handleSendPush} color="default" aria-label="send-push">
                    <SendIcon/>
                </Fab>
            </div>
        </React.Fragment>
    )
}
