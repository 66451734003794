import {Observable, of} from "rxjs"
import {catchError, map} from "rxjs/operators"
import {createObservableAction} from "../utils/redux"
import PromoClient from "../api/PromoClient"
import {PromoCode} from "../entities/PromoCode"
import {List} from "../entities/Response"
import {RefineState} from "../entities/State"

export const addPromoCode = createObservableAction("CREATE_PROMOCODE",
    (client: PromoClient, payload: { code: string, title: string }): Observable<PromoCode> => {
        return of({code: "", title: "", preview: undefined,
            removeCount: 0, maxRemoveCount: 10, openCount: 0, openUniqueCount: 0, useCount: 0, useUniqueCount: 0})
    })

export const editPromoCode = createObservableAction("EDIT_PROMOCODE",
    (client: PromoClient, payload: PromoCode): Observable<PromoCode> => {
        return client.update(payload).pipe(
            map(_ => payload),
            catchError(_ => client.create(payload))
        )
    })

export const removePromoCode = createObservableAction("DELETE_PROMOCODE",
    (client: PromoClient, ref: { code: string }): Observable<{ code: string }> => {
        if (!ref.code) return new Observable(s => {
            s.next(ref)
            s.complete()
        })
        return client.deleteById(ref.code).pipe(map(() => ref))
    })

export const searchPromoCodes = createObservableAction("SEARCH_PROMOCODE",
    (client: PromoClient, params: RefineState): Observable<List<PromoCode>> => {
        const maxResults = 30
        return client.search({
            query: params.search,
            maxResults,
            ...params
        }).pipe(map((x) => ({loadedAll: x.items.length < maxResults, ...x})))
    })