import React from "react"
import {useHistory} from "react-router-dom"
import clsx from "clsx"

import {makeStyles} from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Skeleton from "@material-ui/lab/Skeleton"

import {Wallpaper} from "../../entities/Wallpaper"

const useStyles = makeStyles((theme) => ({
    progress: {
        position: "fixed",
        right: "calc(50% - 25px)",
        top: "calc(50% - 25px)"
    },
    notFound: {
        textAlign: "center",
        '& img': {
            maxWidth: "400px"
        }
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    media: {
        paddingTop: "56.25%",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": "2",
        "-webkitBoxOrient": "vertical"
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": "4",
        "-webkitBoxOrient": "vertical"
    },
    pulseAnimation: {
        animation: "MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;",
    },
    content: {
        flexGrow: 1,
    },
}))

function renderNotFound() {
    return <>
        <img src={"/nothing-found.svg"}/>
        <Typography variant="h5" component="h2">
        </Typography>
    </>
}

function renderSkeleton(classes: any, isFetching: boolean) {
    let i = 0
    const animation = isFetching ? "wave" : false
    const cardMediaClass = clsx(classes.media, isFetching && classes.pulseAnimation)
    return <Grid container spacing={4}>
        {Array(6).fill(1).map(it => (
            <Grid item key={i++} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                    <CardMedia className={cardMediaClass}
                               image="/image-placeholder.png"
                               title=""
                    />
                    <CardContent className={classes.content}>
                        <Skeleton height={28} variant="text" animation={animation}/>
                        <Skeleton height={60} variant="text" animation={animation}/>
                    </CardContent>
                    <CardActions>
                        <Button disabled={true} size="small" color="primary">
                            edit
                        </Button>
                        <Button disabled={true} size="small" color="primary">
                            delete
                        </Button>
                    </CardActions>
                </Card>
            </Grid>

        ))}
    </Grid>
}

interface ContentProps {
    items?: Wallpaper[],
    fetching: boolean,

    onDeleteRequest(id: number): void
    onUpdateRequest(id: number, patch: Wallpaper): void
}

export default function (props: ContentProps) {
    const classes = useStyles()
    const history = useHistory()

    if (props.items === undefined) {
        return renderSkeleton(classes, props.fetching)
    }

    return <>
        {props.fetching && <CircularProgress size={50} className={classes.progress}/>}
        {props.items.length === 0
            ? <div className={classes.notFound}>
                {renderNotFound()}
            </div>
            : <Grid container spacing={4}>
                {props.items.map(it => (
                    <Grid item key={it.id} xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardMedia className={classes.media}
                                       image={`${it.thumbnail || "/image-placeholder.png"}`}
                                       // image={`${it.cover?.source || "/image-placeholder.png"}`}
                                       title=""
                            />
                            <CardContent className={classes.content}>
                                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                                    {it.name}
                                </Typography>
                                <Typography className={classes.description}>
                                    {/*{it.description}*/}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary"
                                        onClick={() => history.push(`/guides/${it.id}`)}>
                                    edit
                                </Button>
                                <Button onClick={() => props.onDeleteRequest(it.id)} size="small" color="secondary">
                                    delete
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                ))}
            </Grid>
        }
    </>
}
