import React, {useEffect} from "react"
import {useDispatch} from "react-redux"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"

import {useClient} from "../ioc"
import WallpaperClient from "../../api/WallpaperClient"
import {createWallpaper} from "../../actions/wallpapers"

interface DialogProps {
    show: boolean,
    onClose(): void
}

let textRef: React.Ref<any> = null

export default function (props: DialogProps) {
    const dispatch = useDispatch()
    const client = useClient(WallpaperClient)
    const handleOk = () => {
        dispatch(createWallpaper(client, {name, file}))
        props.onClose()
    }
    const handleClose = () => props.onClose()
    const [name, setName] = React.useState("")
    const [file, setFile] = React.useState<any>(undefined)
    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                setName("")
                setFile(undefined);
                (textRef as any)?.focus();
            }, 100)
        }
    }, [props.show])
    return <Dialog open={props.show}
                   fullWidth
                   maxWidth={"sm"}
                   onClose={handleClose}
                   aria-labelledby="wallpaper-download-dialog"
    >
        <DialogTitle id="wallpaper-download-dialog">Загрузка обоев</DialogTitle>
        <DialogContent>
            <TextField label="Название"
                       type="text"
                       fullWidth
                       inputRef={ref => textRef = ref}
                       margin="dense"
                       value={name}
                       onChange={event => setName(event.target.value)}
            />
            <form style={{marginTop: "36px"}}>
                <input id="wallpaper-upload-input" type="file"
                       hidden={true}
                       onChange={event => setFile(event.target.files!![0])}/>
                <Button variant="contained" color="primary" onClick={() => {
                    // @ts-ignore
                    document.getElementById("wallpaper-upload-input").click();
                }}>
                    Выбрать файл
                </Button>
                {file && <div style={{marginTop: "8px"}}>{file.name}</div>}
            </form>
        </DialogContent>
        <DialogActions>
            <Button disabled={file == undefined} onClick={handleOk} color="primary">
                загрузить
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
                отмена
            </Button>
        </DialogActions>
    </Dialog>
}
