import React from "react"
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"
import {applyMiddleware, combineReducers, createStore} from "redux"
import {Provider as StoreProvider} from "react-redux"
import thunkMiddleware from "redux-thunk"
import loggerMiddleware from 'redux-logger'

import "./App.css"

import AuthGuard from "./components/auth-guard"
import NotFound from "./components/not-found-route"

import Login from "./components/login-route"
import PromoList from "./components/promo-list"
import WallpapersList from "./components/wallpapers"

import uiReducer from "./reducers/ui"
import sessionReducer from "./reducers/session"
import wallpapersReducer from "./reducers/wallpapers"
import promoReducer from "./reducers/promo"

const store = createStore(combineReducers({
    session: sessionReducer,
    ui: uiReducer,
    promo: promoReducer,
    wallpapers: wallpapersReducer,
}), applyMiddleware(thunkMiddleware, loggerMiddleware))

export default function App() {
    return (
        <StoreProvider store={store}>
            <Router>
                <AuthGuard homeUrl="/"
                           loginUrl="/login">
                    <Switch>
                        <Route exact path="/">
                            {/*<Redirect to="/promo" />*/}
                            <PromoList/>
                        </Route>
                        <Route exact path="/login">
                            <Login/>
                        </Route>
                        <Route exact path="/wallpapers">
                            <WallpapersList/>
                        </Route>
                        <Route exact path="/promo">
                            <PromoList/>
                        </Route>
                        <Route path="/">
                            <NotFound/>
                        </Route>
                    </Switch>
                </AuthGuard>
            </Router>
        </StoreProvider>
    )
}
