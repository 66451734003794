import {from, Observable} from "rxjs"

export function readImageUrl(file: File): Observable<string> {
    return from(new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function() {
            resolve(reader.result as string)
        }
    }))
}

export function resize(origin: File, width: number = 300): Observable<Blob> {
    return from(new Promise<Blob>((resolve, reject) => {
        const image = new Image();
        const onImageLoad = () => {
            if(image.width <= width) {
                resolve(origin);
                return;
            }
            let height = Math.round((width / image.width) * image.height);

            let resizingCanvas: HTMLCanvasElement = document.createElement('canvas');
            let resizingCanvasContext = resizingCanvas.getContext("2d")!!;

            resizingCanvas.width = image.width;
            resizingCanvas.height = image.height;

            resizingCanvasContext.drawImage(image, 0, 0, resizingCanvas.width,
                resizingCanvas.height);

            const curImageDimensions = {
                width: Math.floor(image.width),
                height: Math.floor(image.height)
            };

            const halfImageDimensions = {
                width: 0,
                height: 0
            };

            while (curImageDimensions.width * 0.5 > width) {
                // Reduce the resizing canvas by half and refresh the image
                halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
                halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

                resizingCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
                    0, 0, halfImageDimensions.width, halfImageDimensions.height);

                curImageDimensions.width = halfImageDimensions.width;
                curImageDimensions.height = halfImageDimensions.height;
            }

            let outputCanvas: HTMLCanvasElement = document.createElement('canvas');
            let outputCanvasContext = outputCanvas.getContext("2d")!!;

            outputCanvas.width = width;
            outputCanvas.height = height;

            outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width,
                curImageDimensions.height, 0, 0, width, height);

            outputCanvas.toBlob(blob => {
                resolve(blob!!);
            }, "image/jpeg", 0.85);
        }
        const reader = new FileReader();
        reader.readAsDataURL(origin);
        reader.onloadend = function() {
            image.src = reader.result as string;
            image.onload = onImageLoad;
        }
    }))
}