import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {initialize} from "../../actions/session"
import {AppState} from "../../reducers"
import LoginClient from "../../api/LoginClient"
import {useClient} from "../ioc"

interface AuthGuardProps {
    homeUrl: string,
    loginUrl: string,
    children?: React.ReactNode,
}

export default function AuthGuard(props: AuthGuardProps) {
    const {id, token} = useSelector((state: AppState) => state.session)
    const history = useHistory()
    const [location, setLocation] = useState(history.location.pathname)
    const dispatch = useDispatch()
    const loginClient = useClient(LoginClient)

    useEffect(() => {
        dispatch(initialize(loginClient))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!id) return
        if (token) {
            if (location.startsWith(props.loginUrl)) {
                history.push(props.homeUrl)
            } else {
                history.push(location)
            }
        } else {
            setLocation(history.location.pathname)
            history.push(props.loginUrl)
        }
    }, [id, token]) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        {id && props.children}
    </>
}
