import {Observable, of} from "rxjs"
import {catchError, map, tap} from "rxjs/operators"

import ILoginClient from "../api/LoginClient"
import {createObservableAction} from "../utils/redux"

import {IOAuthToken} from "../entities/Session"

export const login = createObservableAction("LOGIN",
    (client: ILoginClient, login: string, password: string): Observable<IOAuthToken> => {
        return client.fetchToken(login, password)
    })

export const logout = createObservableAction("LOGOUT",
    (client: ILoginClient): Observable<void> => {
        return client.revokeToken()
    })

export const initialize = createObservableAction("SESSION_INIT",
    (client: ILoginClient): Observable<{ id: string, token?: IOAuthToken }> => {
        const sessionId = `session-${(new Date()).toISOString().split(".")[0]}`
        if (client.isAuthorize()) {
            return client.refreshToken()
                .pipe(
                    map(token => ({id: sessionId, token: token})),
                    catchError(_ =>
                            of({id: sessionId}).pipe(tap(_ => localStorage.clear()))
                    )
                )
        } else {
            return of({id: sessionId})
        }
    })
