import {createReducer} from "deox"

import {ItemsState} from "../entities/State"
import {PromoCode} from "../entities/PromoCode"
import {List} from "../entities/Response"
import {addPromoCode, editPromoCode, removePromoCode, searchPromoCodes} from "../actions/promo"

export interface PromoState extends ItemsState<PromoCode> {
}

const defaultPromoState: PromoState = {
    search: undefined,
    pageToken: undefined,
    orderBy: undefined,
    orderDirection: undefined,
    items: [],
    loadedAll: false,
}

function applyPromoCodes(state: PromoState, codes: List<PromoCode>): PromoState {
    const x = {...state, ...codes}
    x.pageToken = codes.nextPageToken
    delete x.nextPageToken
    return x
}

export default createReducer(defaultPromoState, handleAction => [
    handleAction(searchPromoCodes.complete,
        (state: PromoState, event: { type: string, payload: List<PromoCode> }) => {
            return applyPromoCodes(state, event.payload)
        }),
    handleAction(addPromoCode.complete,
        (state: PromoState, event: { type: string, payload: PromoCode }) => {
            const newItems = state.items || []
            newItems.push(event.payload)
            return applyPromoCodes(state, {items: newItems, nextPageToken: state.pageToken})
        }),
    handleAction(editPromoCode.complete,
        (state: PromoState, event: { type: string, payload: PromoCode }) => {
            let newItems = state.items || []
            newItems = newItems.filter(s => s.code)
            const index = newItems.findIndex(s => s.code === event.payload.code)
            if (index >= 0) {
                newItems[index] = event.payload
            } else {
                newItems.push(event.payload)
            }
            return applyPromoCodes(state, {items: newItems, nextPageToken: state.pageToken})
        }),
    handleAction(removePromoCode.complete,
        (state: PromoState, event: { type: string, payload: { code: string } }) => {
            const newItems = [...state.items!!].filter(s => s.code !== event.payload.code)
            return applyPromoCodes(state, {items: newItems, nextPageToken: state.pageToken})
        }),
])