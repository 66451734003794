import React from "react";
import {useHistory} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    background: {
        height: "100vh",
        backgroundColor: "#5c5856",
        backgroundImage: "linear-gradient(45deg, #5c5856 0%, #3c3c81 100%)"
    },
    button: {
        marginTop: "32px",
        color: "white",
        border: "1px solid rgba(255, 255, 255, 1)",
        '&:hover': {
            border: "1px solid rgba(255, 255, 255, 1)",
        }
    },
    header: {
        fontSize: "128px",
        fontWeight: "bold",
    },
    oops: {
        fontSize: "40px",
        fontWeight: "bold",
    },
    text: {
        fontSize: "28px",
        fontWeight: "lighter"
    },
    container: {
        paddingTop: "30vh",
        color: "white",
        textAlign: "center"
    }
}));

export default function NotFound() {
    const classes = useStyles();
    const history = useHistory();
    const onHomeClick = () => {
        history.push("/");
    };
    return <div className={classes.background}>
        <div className={classes.container}>
            <div className={classes.header}>404</div>
            <div className={classes.oops}>Упс!</div>
            <div className={classes.text}>Данная страница не найдена</div>
            <Button onClick={onHomeClick} className={classes.button} variant="outlined">на главную</Button>
        </div>
    </div>;
}
