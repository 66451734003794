import axios from "axios-observable"
import {Observable, of} from "rxjs"
import {map, tap} from "rxjs/operators"
import queryString from "query-string"

import IApplicationConfig from "./config"
import {Wallpaper} from "../entities/Wallpaper"
import {List} from "../entities/Response"
import {SearchQuery} from "../entities/Request"

type CreationParams = {
    name: string,
    origin: Blob,
    preview: Blob
}

export default abstract class WallpaperClient {
    abstract create(req: CreationParams): Observable<Wallpaper>
    abstract search(params: SearchQuery): Observable<List<Wallpaper>>
    abstract fetchById(id: number): Observable<Wallpaper>
    abstract update(wallpaper: Wallpaper): Observable<Wallpaper>
    abstract deleteById(id: number): Observable<void>
}

export class WallpaperClientImpl extends WallpaperClient {
    constructor(private config: IApplicationConfig) {
        super()
    }

    create(req: CreationParams): Observable<Wallpaper> {
        const formData = new FormData()
        formData.append("origin", req.origin)
        formData.append("preview", req.preview)
        return axios.post<Wallpaper>(`${this.config.wallpapersUrl}`,
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).pipe(map(x => x.data))
    }

    search(params: SearchQuery): Observable<List<Wallpaper>> {
        const query = queryString.stringify({
            search: params.query,
            maxResults: params.maxResults,
            pageToken: params.pageToken,
            orderName: params.orderBy,
            orderDirection: params.orderDirection
        })
        return axios.get<List<Wallpaper>>(`${this.config.wallpapersUrl}/?${query}`, this.config.getReqHttpConfig())
            .pipe(map(x => x.data))
    }

    fetchById(id: number): Observable<Wallpaper> {
        return axios.get<Wallpaper>(`${this.config.wallpapersUrl}/${id}`)
            .pipe(map(x => x.data))
    }

    update(wallpaper: Wallpaper): Observable<Wallpaper> {
        return axios.put(`${this.config.wallpapersUrl}/${wallpaper.id}`, {
            name: wallpaper.name,
            favoriteCount: wallpaper.favoriteCount,
            viewCount: wallpaper.viewCount,
        }).pipe(map(x => x.data))
    }

    deleteById(id: number): Observable<void> {
        return axios.delete(`this.config.wallpapersUrl/${id}`)
            .pipe(map(x => void 0))
    }
}
