import {Dispatch} from "redux"
import {from, Observable} from "rxjs"
import {mergeMap, map, catchError} from "rxjs/operators"

import {createObservableAction, createThunkAction} from "../utils/redux"
import WallpaperClient from "../api/WallpaperClient"
import {resize} from "../utils/image"

import {AppState} from "../reducers"
import {Wallpaper} from "../entities/Wallpaper"
import {RefineState} from "../entities/State"

export const fetchWallpapers = createThunkAction("FETCH_WALLPAPERS",
    (client: WallpaperClient) => {
        return async (dispatch: Dispatch, getState: () => AppState) => {
            const refine: RefineState = getState().wallpapers
            client.search({
                maxResults: 50,
                ...refine
            }).pipe(catchError(err => {
                dispatch(fetchWallpapers.error(err))
                throw ""
            })).subscribe(x => {
                dispatch(fetchWallpapers.complete(x))
            })
        }
    })

export const refineWallpapers = createThunkAction("REFINE_WALLPAPERS",
    (payload: RefineState) => {
        return async (dispatch: Dispatch) => {
            setTimeout(() => {
                dispatch(fetchWallpapers())
            }, 50)
            return payload
        }
    })

export const updateWallpaper = createObservableAction("UPDATE_WALLPAPER",
    (client: WallpaperClient, payload: Wallpaper): Observable<Wallpaper> => {
        return client.update(payload)
    })

export const createWallpaper = createObservableAction("CREATE_WALLPAPER",
    (client: WallpaperClient, payload: { name: string, file: File }): Observable<Wallpaper> => {
        return from(resize(payload.file)).pipe(mergeMap((resizedImage: Blob) =>
            client.create({
                name: payload.name,
                origin: payload.file,
                preview: resizedImage
            })))
    })

export const removeWallpaper = createObservableAction("REMOVE_WALLPAPER",
    (client: WallpaperClient, ref: { id: number }): Observable<{ id: number }> => {
        return client.deleteById(ref.id).pipe(map(() => ref))
    })