import {createReducer} from "deox"

import {login} from "../actions/session"
import {createWallpaper, updateWallpaper, fetchWallpapers, removeWallpaper} from "../actions/wallpapers"
import {editPromoCode, removePromoCode, searchPromoCodes} from "../actions/promo"

interface IRequestsScope {
    current: number,

    increment(): IRequestsScope,

    decrement(): IRequestsScope,

    isActive(): boolean,
}

class RequestsScope implements IRequestsScope {
    current: number = 0

    increment(): IRequestsScope {
        const clone = new RequestsScope()
        clone.current = this.current + 1
        return clone
    }

    decrement(): IRequestsScope {
        const clone = new RequestsScope()
        clone.current = Math.max(0, this.current - 1)
        return clone
    }

    isActive(): boolean {
        return this.current > 0
    }
}

type RequestsMap = {
    login: IRequestsScope,
    wallpapers: IRequestsScope,
    promo: IRequestsScope,
}

export type UIState = {
    requests: RequestsMap,
}

const defaultUIState: UIState = {
    requests: {
        login: new RequestsScope(),
        wallpapers: new RequestsScope(),
        promo: new RequestsScope(),
    },
}

function incrementRequests(state: UIState, scope: keyof RequestsMap): UIState {
    const x = {...state.requests}
    x[scope] = x[scope].increment()
    return {...state, ...{requests: x}}
}

function decrementRequests(state: UIState, scope: keyof RequestsMap): UIState {
    const x = {...state.requests}
    x[scope] = x[scope].decrement()
    return {...state, ...{requests: x}}
}

export default createReducer(defaultUIState, handleAction => [
    handleAction(login.next, prevState => incrementRequests(prevState, "login")),
    handleAction(login.error, (prevState, _) =>
        decrementRequests(prevState, "login")),
    handleAction(login.complete, prevState => decrementRequests(prevState, "login")),

    handleAction([createWallpaper.next, updateWallpaper.next, removeWallpaper.next, fetchWallpapers.next],
        prevState => incrementRequests(prevState, "wallpapers")),
    handleAction([createWallpaper.error, updateWallpaper.error, removeWallpaper.error, fetchWallpapers.error],
        (prevState, _) => decrementRequests(prevState, "wallpapers")),


    handleAction([editPromoCode.next, searchPromoCodes.next, removePromoCode.next],
        prevState => incrementRequests(prevState, "promo")),
    handleAction([editPromoCode.error, searchPromoCodes.error, removePromoCode.error],
        (prevState, _) => decrementRequests(prevState, "promo")),
    handleAction([editPromoCode.complete, searchPromoCodes.complete, removePromoCode.complete],
            prevState => decrementRequests(prevState, "promo")),
])
