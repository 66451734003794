import axios from "axios-observable"
import {Observable, of} from "rxjs"
import {map, tap} from "rxjs/operators"

import IApplicationConfig from "./config"
import {IOAuthToken} from "../entities/Session"

export default abstract class LoginClient {
    abstract isAuthorize(): boolean

    abstract fetchToken(login: string, password: string): Observable<IOAuthToken>

    abstract refreshToken(): Observable<IOAuthToken>

    abstract revokeToken(): Observable<void>
}

export class LoginClientImpl extends LoginClient {
    constructor(private config: IApplicationConfig) {
        super()
    }

    isAuthorize(): boolean {
        return this.config.getSessionToken() !== null
    }

    fetchToken(login: string, password: string): Observable<IOAuthToken> {
        const isAdmin = login === "admin" && password === "admin"
        return axios.post<{ accessToken: string, refreshToken?: string }>(
            `${this.config.authUrl}/token`,
            {
                "clientId": "admin",
                "clientSecret": isAdmin ? "f2f6c3ec-284a-4ca5-bf43-5a8bfa22c9f9" : "",
            }, {}).pipe(
            map(x => ({
                accessToken: x.data.accessToken,
                refreshToken: x.data.refreshToken
            })),
            tap(x => {
                localStorage.setItem(this.config.storageUrl, JSON.stringify(x))
                this.config.getSessionToken()
            })
        )
    }

    refreshToken(): Observable<IOAuthToken> {
        return of(JSON.parse(localStorage.getItem(this.config.storageUrl)!!))
    }

    revokeToken(): Observable<void> {
        localStorage.clear()
        return of(void 0)
    }
}
