import {Observable} from "rxjs"
import {Dispatch} from "redux"
import {createActionCreator} from "deox"
import {ExactActionCreator} from "deox/dist/create-action-creator"
import {Action} from "deox/dist/create-action"

export function createThunkAction<T>(actionName: string, cb: Function) {
    return Object.assign(cb, {
        next: createActionCreator(`${actionName}_NEXT`),
        complete: createActionCreator(
            `${actionName}_COMPLETE`,
            resolve => (payload?: T) => resolve(payload)
        ),
        error: createActionCreator(`${actionName}_ERROR`, resolve => error =>
            resolve(error)
        ),
    })
}

export function createObservableAction<T, F extends Function>(actionName: string, cb: F)
    : F & {
    next: ExactActionCreator<string, () => Action<string>>,
    complete: ExactActionCreator<string, <_T>(...args: any[]) => Action<string>>,
    error: ExactActionCreator<string, <_T>(...args: any[]) => Action<string>>
} {
    // @ts-ignore
    const self = this
    const next = createActionCreator(`${actionName}_NEXT`)
    const error = createActionCreator(`${actionName}_ERROR`, resolve => error =>
        resolve(error)
    )
    const complete = createActionCreator(`${actionName}_COMPLETE`, resolve => (payload?: T) =>
        resolve(payload)
    )
    // @ts-ignore
    return Object.assign(function () {
        let isUnit = true
        const params = arguments
        return (dispatch: Dispatch) => {
            dispatch(next())
            const result: Observable<T> = cb.apply(self, params)
            result.subscribe(x => {
                if (typeof x !== "undefined") {
                    isUnit = false
                    dispatch(complete(x))
                }
            }, (err) => dispatch(error(err)), () => {
                isUnit && dispatch(complete())
            })
        }
    }, {next, complete, error})
}
