import axios from "axios-observable"
import {Observable, of} from "rxjs"
import {map, tap} from "rxjs/operators"
import queryString from "query-string"

import IApplicationConfig from "./config"

import {List} from "../entities/Response"
import {PromoCode} from "../entities/PromoCode"
import {SearchQuery} from "../entities/Request"
import {PushMessage} from "../entities/Message"

export default abstract class PromoClient {
    abstract create(promo: PromoCode): Observable<PromoCode>
    abstract update(promo: PromoCode): Observable<PromoCode>
    abstract search(params: SearchQuery): Observable<List<PromoCode>>
    abstract deleteById(code: string): Observable<void>
    abstract sendPushMessage(message: PushMessage): Observable<void>
}

export class PromoClientImpl extends PromoClient {
    constructor(private config: IApplicationConfig) {
        super()
    }

    search(params: SearchQuery): Observable<List<PromoCode>> {
        const query = queryString.stringify({
            search: params.query,
            maxResults: params.maxResults,
            pageToken: params.pageToken,
            orderName: params.orderBy,
            orderDirection: params.orderDirection
        })
        return axios.get<List<PromoCode>>(`${this.config.promoUrl}/search-all?${query}`, this.config.getReqHttpConfig())
            .pipe(map(x => x.data))
    }

    create(promo: PromoCode): Observable<PromoCode> {
        const formData = this.getUpdateDate(promo)
        const config = this.config.getReqHttpConfig()
        config.headers["Content-Type"] = "multipart/form-data"
        return axios.post(`${this.config.promoUrl}`, formData, config)
            .pipe(map(x => x.data))
    }

    update(promo: PromoCode): Observable<PromoCode> {
        const formData = this.getUpdateDate(promo)
        const config = this.config.getReqHttpConfig()
        config.headers["Content-Type"] = "multipart/form-data"
        return axios.put(`${this.config.promoUrl}/${promo.code}`, formData, config)
            .pipe(map(x => x.data))
    }

    private getUpdateDate(promo: PromoCode): FormData {
        const formData = new FormData()
        formData.append("code", promo.code)
        formData.append("title", promo.title)
        formData.append("maxRemoveCount", promo.maxRemoveCount.toString())
        if (promo.preview)
            formData.append("preview", promo.preview)
        return formData
    }

    deleteById(code: string): Observable<void> {
        return axios.delete(`${this.config.promoUrl}/${code}`, this.config.getReqHttpConfig())
            .pipe(map(x => void 0))
    }

    sendPushMessage(message: PushMessage): Observable<void> {
        return axios.post(`${this.config.promoUrl}/message`, message, this.config.getReqHttpConfig())
            .pipe(map(x => void 0))
    }
}
