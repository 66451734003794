import React, {useContext, useMemo} from "react"
import IApplicationConfig from "../api/config"

import LoginClient, {LoginClientImpl} from "../api/LoginClient"
import WallpaperClient, {WallpaperClientImpl} from "../api/WallpaperClient"
import PromoClient, {PromoClientImpl} from "../api/PromoClient"
import {IOAuthToken} from "../entities/Session";

type Constructor<T> = Function & { prototype: T }

interface Context {
    get<T>(ctor: Constructor<T>): T,
}

class IoContainer implements Context {
    private instances: any = {}

    register(name: string, ctor: Constructor<any>, instance: any) {
        this.instances[name] = instance
        // @ts-ignore
        ctor.className = name
    }

    get<T>(ctor: Constructor<T>): T {
        const keys = Object.keys(this.instances)
        // @ts-ignore
        const i = keys.findIndex(s => s.startsWith(ctor.className))
        if (i >= 0) return this.instances[keys[i]]
        throw "not found"
    }
}

const API_HOST = process.env.REACT_APP_API_HOST || "https://masterforroblox.planetcommander.ru/api"

const container = new IoContainer()
let curToken: any = null
const appConfig: IApplicationConfig = {
    storageUrl: "app.settings",
    authUrl: `${API_HOST}/oauth`,
    wallpapersUrl: `${API_HOST}/wallpapers`,
    promoUrl: `${API_HOST}/promo`,
    getSessionToken: () => {
        const x = localStorage.getItem("app.settings")
        if (x != null) {
            curToken = JSON.parse(x)
        }
        return curToken
    },
    getReqHttpConfig: () => {
        return {
            headers: {
                Authorization: `Bearer ${curToken?.accessToken}`
            }
        }
    }
}
container.register("LoginClient", LoginClient, new LoginClientImpl(appConfig))
container.register("WallpaperClient", WallpaperClient, new WallpaperClientImpl(appConfig))
container.register("PromoClient", PromoClient, new PromoClientImpl(appConfig))

const ServicesContext = React.createContext<Context>(container)

export {ServicesContext}

export function useClient<T>(clientConstructor: Constructor<T>): T {
    const services = useContext(ServicesContext)
    return useMemo(() => services.get<T>(clientConstructor), [services])
}
