import React, {useState} from "react"

import {makeStyles} from "@material-ui/core/styles"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"

export type Order = "asc" | "desc"

export interface HeadCell<T> {
    id: keyof T;
    label: string;
    type?: any;
    disablePadding?: boolean;
}

interface EnhancedTableProps<T> {
    classes: ReturnType<typeof useStyles>,
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void,
    orderBy?: string,
    order?: Order,
    headCells: HeadCell<T>[],
}

interface HeadListItem {
    createdAt: string,
    name: string,
    favoriteCount: string,
    viewCount: string
}

const headCells: HeadCell<HeadListItem>[] = [
    {id: "createdAt", label: "по дате"},
    {id: "name", label: "по имени", disablePadding: true},
    {id: "favoriteCount", label: "по лайкам", disablePadding: true},
    {id: "viewCount", label: "по просмотрам", disablePadding: true},
]

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    title: {
        flex: "1 1 100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    search: {
        width: "500px",
    }
}))

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }
    return (
        <TableHead>
            <TableRow>
                {props.headCells.map(headCell => (
                    <TableCell
                        key={headCell.id as string}
                        align={headCell.type ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

interface SearchbarProps {
    title: string,
    search?: string,
    orderBy?: string,
    orderDirection?: Order,
    orderFields?: HeadCell<any>[],
    onOrderChange: (field: string, direction: Order) => void,
    onSearchChange: (text?: string) => void,
}

export default function(props: SearchbarProps) {
    const classes = useStyles()
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof HeadListItem) => {
        if (props.orderBy !== property) {
            props.onOrderChange(property, "asc")
        } else {
            const isAsc = props.orderDirection === "asc"
            props.onOrderChange(property, isAsc ? "desc" : "asc")
        }
    }
    const [search, setSearch] = useState(props.search)
    const [timeoutHandler, setTimeoutHandler] = useState<any | null>(null)
    const debounce = (text?: string, force: boolean = false) => {
        const value = !!(text?.trim()?.length) ? text?.trim() : undefined
        if (timeoutHandler != null) {
            clearTimeout(timeoutHandler)
        }
        if (force) {
            props.onSearchChange(value)
        } else {
            setTimeoutHandler(setTimeout(() => {
                props.onSearchChange(value)
            }, 500))
        }
    }
    return  <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                {props.title}
            </Typography>
            <Tooltip title="поиск по названию">
                <TextField
                    className={classes.search}
                    value={search}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            debounce(search, true)
                        }
                    }}
                    onChange={event => {
                        setSearch(event.target.value)
                        debounce(event.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Tooltip>
        </Toolbar>
        <TableContainer>
            <Table aria-labelledby="tableTitle"
                   size="medium"
                   aria-label="enhanced table">
                <EnhancedTableHead
                    classes={classes}
                    headCells={props.orderFields ?? headCells}
                    order={props.orderDirection}
                    orderBy={props.orderBy}
                    onRequestSort={handleRequestSort}
                />
            </Table>
        </TableContainer>
    </Container>
}
