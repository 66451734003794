import React from "react"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

interface ConfirmProps {
    show: boolean,
    title?: string,
    description?: string,
    onClose(confirm: boolean): void
}

export default function (props: ConfirmProps) {
    const handleOk = () => {
        props.onClose(true);
    };
    const handleClose = () => {
        props.onClose(false);
    };
    const title = props.title || "Удалить?"
    const description = props.description || "Отменить данное действие будет невозможно."
    return <Dialog open={props.show}
                   onClose={handleClose}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {description}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleOk} color="primary">
                ok
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
                отмена
            </Button>
        </DialogActions>
    </Dialog>
}
