import {createReducer} from "deox"
import {IOAuthToken} from "../entities/Session"

import {login, logout, initialize} from "../actions/session"

export type SessionState = {
    id?: string,
    token?: IOAuthToken,
}

const defaultSessionState: SessionState = {}

function loginComplete(state: SessionState,
                       event: { type: string, payload: { id?: string, token?: IOAuthToken } }): SessionState {
    return {...state, ...event.payload}
}

export default createReducer(defaultSessionState, handleAction => [
    handleAction(login.complete, (state: SessionState, event: { type: string, payload: IOAuthToken }) =>
        loginComplete(state, {type: event.type, payload: {token: event.payload}})),
    handleAction(initialize.complete, loginComplete),
    handleAction(logout.complete, _ => {
        const newState = {..._}
        delete newState.token
        return newState
    }),
])
